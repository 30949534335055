import React, {useState, useEffect} from 'react';
import FooterSection from "../../components/FooterSection/Footer";
import FavoritesProfileSection from "../../components/AppSections/ProfileSections/FavoritesProfileSection";
import SavedOnlineDiscountsProfileSection
    from "../../components/AppSections/ProfileSections/SavedOnlineDiscountsProfileSection";
import SavedStoreDiscountsProfileSection
    from "../../components/AppSections/ProfileSections/SavedStoreDiscountsProfileSection";
import {useHistory, useParams} from "react-router-dom";
import {getCompanyFavoritesCall, getProfileCall, getSavingsCall, getSnipTechOnlineFavoriteCall} from "../../api/ApiCalls";
import {useDispatch, useSelector} from "react-redux";
import {hasAValue} from "../../uitls/SharedFunctions";
import ImageComponent from "../../components/AppComponents/ImageComponent";
import {appUrls, appWords} from "../../config/Constants";
import {updateAuthDataNEWRS, updateAuthDataRS, updateEmailInModal} from "../../redux/reduxActions/reduxStoreActions";
import UserDetailsProfileSection from "../../components/AppSections/ProfileSections/UserDetailsProfileSection";
import {stringsTranslate} from "../../locales/CustomTranslater";


export default function ProfilePage() {

    const initStrings = {
        option: stringsTranslate(appWords.profiel_option),
        option1: stringsTranslate(appWords.profiel_option_1),
        option2: stringsTranslate(appWords.profiel_option_2),
        option3: stringsTranslate(appWords.profiel_option_3),
        option4: stringsTranslate(appWords.profiel_option_4),
        option5: stringsTranslate(appWords.profiel_option_5),
    }
    const storeDiscountsRS = useSelector(state => state.storeDiscountsRS);
    const dispatch = useDispatch()
    const {name} = useParams();

    const history = useHistory();

    const [showSection, setShowSection] = useState(1);
    const [savings, setSavings] = useState([])
    const [companyFavorites, setCompanyFavorites] = useState([])
    const [snipTechFavorites, setSnipTechFavorites] = useState([])
    const [user, setUser] = useState(undefined)

    useEffect(() => {
        getProfile()
        getSavings()
        getSnipTechFavorites();
        getCompanyFavorites()
    }, []);


    return (
        <section className="section align-items-start justify-content-start mt-5 ">
            <div className="container-fluid mt-5 ">
                <div className="row section-profile pb-4 border-bottom">
                    <div className="col-lg-3 col-md-12 col-sm-12 border-right">
                        {hasAValue(user) && <div className={"text-center"}>
                            {hasAValue(user) ? (
                                <ImageComponent
                                    src={"/assets/images/account/avatar.png"}
                                    className="profile-pic radius-100"
                                    alt=""
                                />
                            ) : null}
                                <h2 className="my-3 flex-wrap text-break">
                                    {user.firstName || ''} {''} {user.lastName || ''}
                                </h2>
                            {user?.knaekCode?.code && (
                                <p className="text-primary m-2 font-weight-bold">KNAEK-ID: {user?.knaekCode?.code}</p>
                            )}

                        </div>}


                        <div className={"row d-flex justify-content-start profile-element1-border pb-5"}>
                            <div className={"col-12 text-left"}>
                                <div className={"mt-5 profile-element1-links"}>
                                    <a className="svg-profile-options ml-4 cursor-pointer"
                                       onClick={() => changeShowenSection("")}>
                                        <svg width="20" height="20" viewBox="0 0 32 32"
                                             className="mr-3">
                                            <path
                                                d="M28,2H10.67a4,4,0,0,0-4,4v6.67H4a4,4,0,0,0-4,4V26a4,4,0,0,0,4,4H21.33a4,4,0,0,0,4-4V19.33H28a4,4,0,0,0,4-4V6A4,4,0,0,0,28,2ZM22.67,18v8a1.33,1.33,0,0,1-1.34,1.33H4A1.32,1.32,0,0,1,2.67,26V16.67A1.33,1.33,0,0,1,4,15.33H21.33a1.34,1.34,0,0,1,1.34,1.34Zm6.66-2.67A1.33,1.33,0,0,1,28,16.67H25.33a4,4,0,0,0-4-4h-12V9.33h20Zm0-8.66h-20V6a1.33,1.33,0,0,1,1.34-1.33H28A1.32,1.32,0,0,1,29.33,6Zm-20.66,14V22a1.33,1.33,0,0,1-1.34,1.33H6A1.32,1.32,0,0,1,4.67,22V20.67A1.33,1.33,0,0,1,6,19.33H7.33A1.34,1.34,0,0,1,8.67,20.67Z"></path>
                                        </svg>
                                        {initStrings.option}
                                    </a>
                                </div>
                                <div className={"mt-4 profile-element1-links"}>
                                    <a className="svg-profile-options ml-4 cursor-pointer"
                                       onClick={() => changeShowenSection("online-bespaard")}>
                                        <svg width="20" height="20" viewBox="0 0 32 32"
                                             className="mr-3">
                                            <path
                                                d="M26,0H6.67A1.34,1.34,0,0,0,5.33,1.33V4.67H4A1.32,1.32,0,0,0,2.67,6V9.33H1.33A1.34,1.34,0,0,0,0,10.67v20A1.34,1.34,0,0,0,1.33,32H26.67A5.33,5.33,0,0,0,32,26.67V6A6,6,0,0,0,26,0ZM5.33,7.33H24v2H5.33Zm-2.66,12h4a1.34,1.34,0,0,1,0,2.67h-4Zm26.66,7.34a2.66,2.66,0,0,1-2.66,2.66h-24V24.67h4a4,4,0,0,0,0-8h-4V12H26a6,6,0,0,0,3.33-1ZM26.67,9.27V6a1.33,1.33,0,0,0-1.34-1.33H8v-2H26a3.33,3.33,0,0,1,.67,6.6Z"></path>
                                        </svg>
                                        {initStrings.option2}
                                    </a>
                                </div>
                                <div className={"mt-4 profile-element1-links"}>
                                    <a className="svg-profile-options ml-4 cursor-pointer"
                                       onClick={() => changeShowenSection("bespaard-kortingen")}>
                                        <svg width="20" height="20" viewBox="0 0 32 32"
                                             className="mr-3">
                                            <path
                                                d="M30.67,22H28V13.59a5.8,5.8,0,0,0,4-5.44,1.32,1.32,0,0,0-.18-.67l-4-6.82A1.33,1.33,0,0,0,26.67,0H5.33A1.33,1.33,0,0,0,4.18.66l-4,6.82A1.32,1.32,0,0,0,0,8.15a5.8,5.8,0,0,0,4,5.44V22H1.33A1.34,1.34,0,0,0,0,23.33v7.34A1.34,1.34,0,0,0,1.33,32H30.67A1.34,1.34,0,0,0,32,30.67V23.33A1.34,1.34,0,0,0,30.67,22ZM2.69,8.49,6.1,2.67H25.9l3.41,5.82A3.25,3.25,0,0,1,26,11.26,3.68,3.68,0,0,1,22.67,8,1.34,1.34,0,0,0,20,8a4,4,0,0,1-8,0A1.34,1.34,0,0,0,9.33,8,3.34,3.34,0,0,1,6,11.26,3.25,3.25,0,0,1,2.69,8.49Zm22.64,5.39V22H6.67V13.88a6,6,0,0,0,3.87-2.06,6.65,6.65,0,0,0,11-.08A6.11,6.11,0,0,0,25.33,13.88Zm-18,15.45H2.67V24.67H7.33Zm7.34,0H10V24.67h4.67Zm7.33,0H17.33V24.67H22Zm7.33,0H24.67V24.67h4.66Z"></path>
                                        </svg>
                                        {initStrings.option3}
                                    </a>
                                </div>
                                <div className={"mt-4 profile-element1-links"}>
                                    <a className="svg-profile-options ml-4 cursor-pointer"
                                       onClick={() => changeShowenSection("favorieten")}>
                                        <svg width="20" height="20" viewBox="0 0 32 32"
                                             className="mr-3">
                                            <path
                                                d="M16,30.67A1.34,1.34,0,0,1,14.67,32h-12a1.33,1.33,0,0,1-1.33-1.33V1.33A1.33,1.33,0,0,1,2.67,0H24a1.34,1.34,0,0,1,1.34,1.33V8a1.34,1.34,0,0,1-2.67,0V2.67H4V29.33H14.67A1.34,1.34,0,0,1,16,30.67ZM12.67,5.33H7.34A1.34,1.34,0,1,0,7.34,8h5.33a1.34,1.34,0,0,0,0-2.67Zm0,4.67H7.34a1.34,1.34,0,1,0,0,2.67h5.33a1.34,1.34,0,0,0,0-2.67Zm0,4.67H7.34a1.33,1.33,0,1,0,0,2.66h5.33a1.33,1.33,0,0,0,0-2.66Zm0,4.66H7.34a1.34,1.34,0,1,0,0,2.67h5.33a1.34,1.34,0,0,0,0-2.67ZM18,5.33A1.34,1.34,0,0,0,18,8h1.34a1.34,1.34,0,0,0,0-2.67Zm1.34,7.34a1.34,1.34,0,0,0,0-2.67H18a1.34,1.34,0,0,0,0,2.67ZM30.2,22.14c-.79,1.54-4.57,4.88-5.32,5.53l-.05,0-.09.06-.14.08-.09,0-.17.05-.08,0A1.15,1.15,0,0,1,24,28h0a1.07,1.07,0,0,1-.25,0l-.08,0-.17-.05-.09,0-.15-.08-.08-.06a.1.1,0,0,1-.05,0c-.75-.65-4.54-4-5.33-5.53a4.28,4.28,0,0,1,1.68-5.64,4,4,0,0,1,3.11-.32,4.1,4.1,0,0,1,1.41.76,4.06,4.06,0,0,1,1.42-.76,4,4,0,0,1,3.1.32A4.27,4.27,0,0,1,30.2,22.14Zm-3-3.31a1.37,1.37,0,0,0-1-.11,1.49,1.49,0,0,0-.86.74.57.57,0,0,0,0,.14.14.14,0,0,0,0,.06l-.05.14-.06.11a.41.41,0,0,1-.07.11.36.36,0,0,1-.07.1l-.09.1-.09.08-.11.08-.1.07-.12.05-.13.05-.06,0h-.06l-.14,0h-.25l-.15,0h-.05l-.06,0-.14-.05-.11-.05-.11-.07-.1-.08-.09-.08a.28.28,0,0,1-.09-.1L22.9,20l-.07-.11,0-.11a.78.78,0,0,1-.05-.14.14.14,0,0,1,0-.06c0-.05,0-.1,0-.14a1.44,1.44,0,0,0-.86-.73,1.34,1.34,0,0,0-1,.1,1.58,1.58,0,0,0-.59,2.09,26,26,0,0,0,3.82,4,26.71,26.71,0,0,0,3.83-4A1.59,1.59,0,0,0,27.23,18.83Z"></path>
                                        </svg>
                                        {initStrings.option4}
                                    </a>
                                </div>
                                <div className={"mt-4 profile-element1-links"} id={"info"}>
                                    <a className="svg-profile-options ml-4 cursor-pointer"
                                       onClick={() => {
                                            dispatch(updateAuthDataNEWRS(null));
                                            dispatch(updateEmailInModal(null));
                                        }}>
                                        <svg width="20" height="20" viewBox="0 0 32 32"
                                             className="mr-3">
                                            <path
                                                d="M26.88,17.5H9.5a1.5,1.5,0,0,1,0-3H26.88l-1.94-1.94a1.5,1.5,0,0,1,2.12-2.12l4.5,4.5a.52.52,0,0,1,.1.11l0,0,.05.07a.21.21,0,0,0,0,.06l0,.06a.16.16,0,0,0,0,.07l0,.07a.14.14,0,0,0,0,.06l0,.07s0,0,0,.07a.24.24,0,0,1,0,.08.19.19,0,0,1,0,.08.13.13,0,0,1,0,.06A.76.76,0,0,1,32,16h0a.76.76,0,0,1,0,.15.13.13,0,0,1,0,.06.19.19,0,0,1,0,.08.24.24,0,0,1,0,.08s0,0,0,.07l0,.07a.14.14,0,0,0,0,.06l0,.07a.16.16,0,0,0,0,.07l0,.06,0,.06-.05.07,0,.05-.09.11h0l-4.5,4.5a1.5,1.5,0,0,1-2.12,0,1.49,1.49,0,0,1,0-2.12ZM0,4V28a1.5,1.5,0,0,0,1.5,1.5h19A1.5,1.5,0,0,0,22,28V22a1.5,1.5,0,0,0-3,0v4.5H3V5.5H19V10a1.5,1.5,0,0,0,3,0V4a1.5,1.5,0,0,0-1.5-1.5H1.5A1.5,1.5,0,0,0,0,4Z"></path>
                                        </svg>
                                        {initStrings.option5}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9 col-md-12 col-sm-12 text-left">
                        {(!name) && <UserDetailsProfileSection user={user} storeDiscountsRS={storeDiscountsRS}/>}
                        {(hasAValue(name) && name.toString().toLowerCase().includes("online-bespaard")) &&
                            <SavedOnlineDiscountsProfileSection/>}
                        {(hasAValue(name) && name.toString().toLowerCase().includes("bespaard-kortingen")) &&
                            <SavedStoreDiscountsProfileSection savings={savings} storeDiscountsRS={storeDiscountsRS}/>}
                        {(hasAValue(name) && name.toString().toLowerCase().includes("favorieten")) &&
                            <div>
                                {hasAValue(companyFavorites) && <FavoritesProfileSection companyFavorites={companyFavorites} snipTechFavorites={snipTechFavorites}/>}
                            </div>
                        }
                    </div>


                </div>
                <FooterSection/>
            </div>

        </section>
    );


    function getProfile() {
        getProfileCall().then(result => {
            setUser(result);
        }).catch(error => {
            console.log("getProfileCall error ", error)
        })
    }

    function getSavings() {
        getSavingsCall().then(result => {
            setSavings(result.savings);
        }).catch(error => {
            console.log("getProfileCall error ", error)
        })
    }
    
    function getCompanyFavorites() {
        getCompanyFavoritesCall().then(result => {
            setCompanyFavorites(result);
        }).catch(error => {
            console.log("getProfileCall error ", error)
        })
    }

    function getSnipTechFavorites() {
        getSnipTechOnlineFavoriteCall().then(result => {
            setSnipTechFavorites(result.items);
        }).catch(error => {
            console.log("getProfileCall error ", error)
        })
    }

    function changeShowenSection(routing) {
        history.push('/account/' + routing);
        if (window.innerWidth <= 768) { // Adjust the width threshold as needed
            window.location.href = '#info';
        }
    }
}
